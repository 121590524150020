<template>
  <div>
    <div class="flex flex-row pb-3">
      <div class="w-1/2">
        <el-input
          v-model="querySearch"
          placeholder="Buscar por nombre, apellido o correo electrónico"
        >
          <i slot="prefix" class="mt-2 text-xl bx bx-search" />
        </el-input>
      </div>
    </div>
    <div class="py-2">
      <div class="block w-full overflow-x-auto">
        <table class="items-center w-full border-collapse">
          <thead>
            <tr class="px-2 border-b-2">
              <th
                class="py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap"
              ></th>
              <th
                class="py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap"
              >
                Nombre
              </th>
              <th
                class="py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap"
              >
                Email
              </th>
              <th
                class="py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap"
              >
                Teléfono
              </th>
              <th
                class="py-3 font-bold text-left border-l-0 border-r-0 whitespace-nowrap"
              >
                Ordenes
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="!clients.length">
              <td :colspan="6">
                <div class="flex flex-col w-full py-8 text-center">
                  <span>{{ emptyMessage }}</span>
                </div>
              </td>
            </tr>
            <tr
              v-for="client in clients"
              :key="client.id"
              class="cursor-pointer hover:bg-gray-100 hover:shadow"
            >
              <td class="p-2 border-t" style="min-width: 50px; width: 50px">
                <el-popover placement="right" width="100" trigger="hover">
                  <div class="w-full flex flex-row text-center">
                    <div class="w-1/2">
                      <button
                        title="Editar"
                        class="el-button--primary rounded-full cursor-pointer"
                        @click="onEdit(client)"
                      >
                        <i
                          class="bx bx-edit"
                          style="padding: 4px; font-size: 20px"
                        ></i>
                      </button>
                    </div>
                    <div class="w-1/2">
                      <button
                        title="Eliminar"
                        class="el-button--danger rounded-full cursor-pointer"
                        @click="onDelete(client)"
                      >
                        <i
                          class="bx bx-trash"
                          style="padding: 4px; font-size: 20px"
                        ></i>
                      </button>
                    </div>
                  </div>
                  <el-button slot="reference" type="text" class="w-full">
                    <button
                      class="el-button--primary rounded-full cursor-pointer"
                    >
                      <i class="text-2xl bx bx-chevron-right"></i>
                    </button>
                  </el-button>
                </el-popover>
              </td>
              <td class="p-2 border-t">
                {{ client.fullName }}
              </td>
              <td class="p-2 border-t">
                {{ client.email }}
              </td>
              <td class="p-2 border-t">
                {{ client.phone }}
              </td>
              <td class="p-2 border-t">
                <el-button
                  @click="$router.push({ name: 'orders.client', params: { id: client.id, fullName: client.fullName }})">
                  Ver Ordenes
                </el-button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import ClientService from "@/services/ClientService";
import error from "@/mixins/error";
import TheHeaderAppServices from "@/components/organisms/services/TheHeaderAppServices";

export default {
  name: "ClientsList",
  mixins: [error],
  data() {
    return {
      querySearch: null,
      clients: [],
      loadingClientes: false,
      clientProps: [
        { key: "fullName", label: "Nombre" },
        { key: "email", label: "Email" },
        { key: "phone", label: "Teléfono" },
      ],
      headerAppSubscription: null,
    };
  },
  computed: {
    emptyMessage() {
      return this.querySearch !== null
        ? `No se encontraron clientes que coincidan con "${this.querySearch}"`
        : "No se han agregado clientes";
    },
  },
  watch: {
    querySearch: _.debounce(async function (querySearch) {
      await this.loadClients(querySearch);
    }, 500),
  },
  async mounted() {
    await this.loadClients();
  },
  created() {
    this.headerAppSubscription = TheHeaderAppServices.command.subscribe(this.handleHeaderAppCommands);
  },
  destroyed() {
    this.headerAppSubscription.unsubscribe();
  },
  methods: {
    async loadClients(querySearch) {
      try {
        this.clients = await ClientService.getAll({
          textSearch: querySearch,
          businessInfoId: this.$store.state.business.id,
        });
      } catch (error) {
        this.$toastr.error(this.getErrorMessage(error));
      }
    },
    onEdit(clientToEdit) {
      this.$emit("onEdit", clientToEdit);
    },
    onDelete(client) {
      this.$confirm(
        `¿Está seguro que desea eliminar al cliente ${client.fullName}?`,
        {
          type: "warning",
          confirmButtonText: "OK",
          cancelButtonText: "Cancelar",
        }
      ).then(async () => {
        await this.remove(client);
      });
    },
    async remove(client) {
      try {
        await ClientService.remove(client.id);
        const indexOfCurrentClient = this.clients.findIndex(
          (c) => c.id === client.id
        );
        this.clients.splice(indexOfCurrentClient, 1);
        this.$toastr.s("Se borro con exito");
      } catch {
        this.$toastr.e("Ocurrió un error al eliminar el cliente");
      }
    },
    handleHeaderAppCommands({ type }) {
      switch(type) {
        case TheHeaderAppServices.commands.onBusinessChange:
          this.loadClients(this.querySearch);
          break;
        default:
      }
    },
  },
};
</script>
